/* globals $ */

(() => {

    const initTreeCollapse =  () => {
        const collapsible = document.querySelectorAll('.tree-collapse .collapse');

        for(let item of collapsible) {
            $(item).on('hide.bs.collapse', event => {
                const link = event.target.querySelector('[data-toggle="collapse"]')

                if(link && link.href) {
                    $(link.href.split('/').slice(-1)[0]).collapse('hide');
                }
            })
        }

    }

    document.addEventListener('turbolinks:load', initTreeCollapse, false)

})()